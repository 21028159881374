import { useState } from 'react'

import _ from 'lodash'

import {
    Box,
    Center,
    CloseButton,
    Editable,
    EditableInput,
    EditablePreview,
    EditableTextarea,
    Flex,
    Heading,
    HStack,
    Stack,
    Tag,
    TagLabel,
    TagLeftIcon,
    TagRightIcon,
    Text,
    useColorModeValue,
} from '@chakra-ui/react'
import {
  AddIcon,
  MinusIcon,
} from '@chakra-ui/icons'

import { baseUrl } from '../env'
import dayjs from '../dayjs'
import CardButtons from './CardButtons'
import getIncentivesFromUida from '../util/getIncentivesFromUida'

import { confirmableCardColors as styles } from '../styles'

import { FullTaskOccurrence } from '../types/models'
import { Filter, BinaryFilter, IsoDate, StateUser } from '../types/utility'

interface Style {
  dayColor: string,
  bg: string,
  descriptionColor: string
}
 
export default function TaskOccurrenceCard(
  {
    occurrence,
    occurrences,
    setOccurrences,
    updateOccurrences,
    filters,
    setFilters
  }:
  {
    occurrence: FullTaskOccurrence,
    occurrences: FullTaskOccurrence[],
    setOccurrences: StateUser<(FullTaskOccurrence|null)[]>,
    updateOccurrences: () => void,
    filters: Filter[],
    setFilters: StateUser<Filter[]>
  }) {
    const [deletionIsConfirmable, setDeletionIsConfirmable] = useState(false)

    const [nameEditMode, setNameEditMode] = useState(false)
    const [name, setName] = useState(occurrence.task.name)
    const [descriptionEditMode, setDescriptionEditMode] = useState(false)
    const [description, setDescription] = useState(occurrence.task.description)
    const [tagEditMode, setTagEditMode] = useState(false)
    const [dateEditMode, setDateEditMode] = useState(false)
    const [date, setDate] = useState(occurrence.date)
    const { urgency: tU, importance: tI, difficulty: tD, avoidance: tA } = occurrence.task
    const defaultUida = '' + tU + tI + tD + tA
    const [uidaEditMode, setUidaEditMode] = useState(false)
    const [uida, setUida] = useState(defaultUida)
    const handleUidaSubmit = (s: string) => {
      if (!/^[01234]{4}$/.test(s)) {
        setUida(defaultUida)
        return
      }
      setUidaEditMode(false)
      setUida(s)
      fetch(`${baseUrl}/tasks/${occurrence.task.id}`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          urgency: +s[0],
          importance: +s[1],
          difficulty: +s[2],
          avoidance: +s[3]
        })
      })
      .then(() => {
        const incentives = getIncentivesFromUida(s)
        if (!incentives.length) return
        const occurrenceIncentives = incentives.map(([type, quantity]) => ({
          type: type.replace('Task', 'TaskOccurrence') as
            'TaskOccurrenceStart'|'TaskOccurrenceContinue'|'TaskOccurrenceComplete',
          quantity,
          taskOccurrenceId: occurrence.id
        }))
        return Promise.all(occurrenceIncentives.map(i => {
          const existingIncentive = occurrence.incentives.find(oi => oi.type === i.type)
          if (existingIncentive) {
            if (existingIncentive.quantity !== i.quantity) {
              return fetch(`${baseUrl}/incentives/${existingIncentive.id}/replace`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ quantity: i.quantity })
              })
            }
            return null
          }
          return fetch(`${baseUrl}/incentives`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(i)
          })
        })
        .filter(el => el))
      })
      .then(() => updateOccurrences())
    }
    const [editableIncentiveId, setEditableIncentiveId] = useState<string|null>(null)

    const { task, resolutionState } = occurrence

    let style : Style
    if (Object.keys(styles).includes(resolutionState.toLowerCase())) {
      // @ts-ignore
      style = styles[resolutionState.toLowerCase()]
    } else {
      style = styles['fallback']
    }
    const titleColor = useColorModeValue('gray.700', 'white')

    const allIncentives = [
      ...occurrence.incentives,
      ...occurrence.task.incentives,
      ...occurrence.task.tactics.flatMap(tactic => tactic.incentives)
    ]
    const tacticIncentives = allIncentives.filter(i => ['Tactic', 'TaskTactic', 'TacticUse'].includes(i.type))
    const nonTacticIncentives = allIncentives.filter(i => !['Tactic', 'TaskTactic', 'TacticUse'].includes(i.type))

    const tacticsRow = <HStack>{task.tactics.map(tactic => {
      // Prioritize higher specificity incentives
      const incentiveForTacticUse = tacticIncentives.find(i => i.tacticId === tactic.id && occurrence.id === i.taskOccurrenceId)
        || tacticIncentives.find(i => i.tacticId === tactic.id)
      const incentiveQuantity = incentiveForTacticUse ? `: ${incentiveForTacticUse.quantity}` : ''
      return (
        <Tag key={tactic.id} colorScheme='purple'>
          <TagLabel>{`${tactic.name}${incentiveQuantity}`}</TagLabel>
        </Tag>
      )}
    )}</HStack>


    const getGoalColorScheme = (goalName: string) => {
      const currentFilter = filters.find(f => f.type === 'goal' && f.value === goalName) as BinaryFilter | undefined
      return !currentFilter ? 'blue' : currentFilter.positive ? 'green' : 'red'
    }
    const goalRow = <HStack>{task.goals.map(goal => (
      <Tag key={goal.id} colorScheme={getGoalColorScheme(goal.name)} onClick={e => e.stopPropagation()}>
        <TagLeftIcon boxSize="12px" as={MinusIcon}
          onClick={e => {
            setNegativeFilter('goal', goal.name)
            e.stopPropagation()
          }}
        />
        <TagLabel>{goal.name}</TagLabel>
        <TagRightIcon boxSize="12px" as={AddIcon}
          onClick={e => {
            togglePositiveFilter('goal', goal.name)
            e.stopPropagation()
          }}
        />
      </Tag>
    ))}</HStack>
    const getTagColorScheme = (tagName: string) => {
      const currentFilter = filters.find(f => f.type === 'tag' && f.value === tagName) as BinaryFilter | undefined
      return !currentFilter ? 'gray' : currentFilter.positive ? 'green' : 'red'
    }
    const tagRow = <HStack width='full' onClick={e => { e.stopPropagation(); setTagEditMode(true) }}>
      {task.tags.map(tag => (
      <Tag
        size='sm'
        key={tag}
        colorScheme={getTagColorScheme(tag)}
      >
        <TagLeftIcon boxSize="12px" as={MinusIcon}
          onClick={e => {
            setNegativeFilter('tag', tag)
            e.stopPropagation()
          }}
        />
        <TagLabel>{tag}</TagLabel>
        <TagRightIcon boxSize="12px" as={AddIcon}
          onClick={e => {
            togglePositiveFilter('tag', tag)
            e.stopPropagation()
          }}
        />
      </Tag>
    ))}
    </HStack>
    const setNegativeFilter = (type: 'tag'|'goal', value: string) => {
      setFilters([...filters, { positive: false, type, value }])
    }
    const togglePositiveFilter = (type: 'tag'|'goal', value: string) => {
      const currentFilter = filters.find(f => f.type === type && f.value === value) as BinaryFilter | undefined
      if (currentFilter) {
        setFilters(filters.filter(f => f !== currentFilter))
      } else {
        setFilters([...filters, { positive: true, type, value }])
      }
    }

    return (
      <Center py={3}>
        <Box
          bg={style.bg}
          boxShadow='2xl'
          rounded='md'
          p={6}
          overflow='hidden'
        >
          <Stack>
            <Flex justifyContent='space-between'>
                {!dateEditMode ?
                <Text
                  color={style.dayColor}
                  textTransform='uppercase'
                  fontWeight={800}
                  fontSize='sm'
                  letterSpacing={1.1}
                  float='left'
                  onClick={e => { e.stopPropagation(); setDateEditMode(true) }}
                >
                  {date ? dayjs(date).format('dddd') : 'Undated'}
                </Text> :
                <Editable
                  defaultValue={date || 'Undated'}
                  onSubmit={async sRaw => {
                    let s = sRaw.trim()
                    if (s.toLowerCase() === 'today') {
                      s = dayjs().format('YYYY-MM-DD')
                    }
                    if (s.toLowerCase() === 'tomorrow') {
                      s = dayjs().add(1, 'day').format('YYYY-MM-DD')
                    }
                    if (s.match(/^\d{1,2}[-/]\d{1,2}$/)) {
                      s = s.replace('/', '-')
                      const [m, d] = s.split('-')
                      s = dayjs().format('YYYY') + '-' + m.padStart(2, '0') + '-' + d.padStart(2, '0')
                    }
                    if (s === 'Undated') {
                      setDateEditMode(false)
                      return
                    }
                    if (!s) {
                      if (occurrence.date) {
                        setDate(null)
                        setDateEditMode(false)
                        await fetch(`${baseUrl}/tasks/${task.id}/occurrences/${occurrence.id}`, {
                          method: 'PATCH',
                          headers: { 'Content-Type': 'application/json' },
                          body: JSON.stringify({ date: null })
                        })
                        updateOccurrences()
                      }
                      return
                    }
                    if (s && !dayjs(s).isValid()) return

                    setDate(s as IsoDate)
                    setDateEditMode(false)
                    await fetch(`${baseUrl}/tasks/${task.id}/occurrences/${occurrence.id}`, {
                      method: 'PATCH',
                      headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify({ date: s })
                    })
                    updateOccurrences()
                  }}
                  onClick={e => e.stopPropagation()}
                  >
                    <EditablePreview />
                    <EditableInput />
                  </Editable>
              }
              <Stack
                shouldWrapChildren
                direction='row'
                onClick={e => e.stopPropagation()}
              >
              <Editable
                size='xs'
                maxWidth={20}
                onEdit={() => setUidaEditMode(true)}
                onChange={setUida}
                onSubmit={s => handleUidaSubmit(s)}
                value={uidaEditMode ?
                  uida :
                  uida.split('').map(Number).map((val, i) => {
                    if (val === 0 || Number.isNaN(val)) return ['U', 'I', 'D', 'A'][i]
                    return ['🟢', '🟡','🔴','❗'][val - 1]
                  })
                  .join('')}
                >
                <EditablePreview />
                <EditableInput/>
              </Editable>
              <MinusIcon onClick={() => {
                // Toggle inclusion in collapsedTasks
                const collapsedTasks = JSON.parse(localStorage.getItem('collapsedTasks') || '[]')
                localStorage.setItem('collapsedTasks', JSON.stringify(
                  collapsedTasks.includes(task.id) ?
                  collapsedTasks.filter((t: string) => t !== task.id) :
                  [...collapsedTasks, task.id]
                ))
                setOccurrences([...occurrences, null])
               }}/>
              <CloseButton
                color={deletionIsConfirmable ? 'red' : 'black'}
                onClick={async () => {
                if (deletionIsConfirmable) {
                  await fetch(`${baseUrl}/tasks/${task.id}/occurrences/${occurrence.id}`, {
                    method: 'DELETE'
                  })
                  updateOccurrences()
                } else {
                  setDeletionIsConfirmable(true)
                  setTimeout(() => setDeletionIsConfirmable(false), 2000)
                }
              }}/>
              </Stack>
            </Flex>
            {!nameEditMode ?
              <Heading
                color={titleColor}
                fontSize={'2xl'}
                fontFamily={'body'}
                onClick={e => { e.stopPropagation(); setNameEditMode(true) }}
              >
                {task.name}
              </Heading> :
              <Editable
                defaultValue={name}
                onSubmit={async s => {
                  const sTrimmed = s.trim()
                  if (!sTrimmed) return
                  setNameEditMode(false)
                  if (sTrimmed !== name) {
                    setName(sTrimmed)
                    await fetch(`${baseUrl}/tasks/${task.id}`, {
                      method: 'PATCH',
                      headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify({ name: sTrimmed })
                    })
                    updateOccurrences()
                  }
                }}
                onClick={e => e.stopPropagation()}
              >
                <EditablePreview />
                <EditableInput />
              </Editable>
            }
            {!descriptionEditMode ?
              <Text
                color={style.descriptionColor}
                onClick={e => { e.stopPropagation(); setDescriptionEditMode(true) }}
              >
                {/* Silly, but prevents the cards from scrunching if the name and desc are short */}
                {(task.description || '').padEnd(40, String.fromCharCode(8194)).slice(0, 100)}
              </Text> :
              <Editable
                defaultValue={description || ''}
                onSubmit={async s => {
                  const sTrimmed = s.trim()
                  setDescriptionEditMode(false)
                  if (sTrimmed !== description) {
                    setDescription(sTrimmed)
                    await fetch(`${baseUrl}/tasks/${task.id}`, {
                      method: 'PATCH',
                      headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify({ description: sTrimmed || null })
                    })
                    updateOccurrences()
                  }
                }}
                onClick={e => e.stopPropagation()}
              >
                <EditablePreview w='full'/>
                <EditableTextarea w='full'/>
              </Editable>
            }
            { goalRow }
            { tacticsRow }
            { tagEditMode ? null : !task.tags.length ?
              <Text
                color={style.descriptionColor}
                onClick={e => { e.stopPropagation(); setTagEditMode(true) }}
              >
                No tags
              </Text> : tagRow
            }
            {
              !tagEditMode ? null :
              <Editable
                defaultValue={task.tags.join(', ')}
                placeholder='No tags'
                onSubmit={async s => {
                  const tags = s.split(',').map(t => t.trim()).filter(t => t)
                  task.tags = tags
                  await fetch(`${baseUrl}/tasks/${task.id}`, {
                    method: 'PATCH',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ tags })
                  })
                  updateOccurrences()
                  setTagEditMode(false)
                }}
                onClick={e => e.stopPropagation()}
              >
                <EditablePreview />
                <EditableInput />
              </Editable>
            }
            { occurrence.activeTime && occurrence.activeTime > new Date().toISOString() ?
              <Text color={style.descriptionColor}>
                Active at: {dayjs(occurrence.activeTime).format('MMMM D, h:mm A')}
              </Text> : null
            }
            {
              nonTacticIncentives.length ?
              <HStack>
                {_.sortBy(
                  nonTacticIncentives,
                  i => [
                    'TaskOccurrenceStart',
                    'TaskOccurrenceContinue',
                    'TaskOccurrenceComplete',
                    'TaskStart',
                    'TaskContinue',
                    'TaskComplete'
                  ].indexOf(i.type)
                )
                .map(incentive => {
                  const typeNameMap = {
                    'TaskStart': 'Start',
                    'TaskContinue': 'Continue',
                    'TaskComplete': 'Complete',
                    'TaskOccurrenceStart': 'Start (TO)',
                    'TaskOccurrenceContinue': 'Continue (TO)',
                    'TaskOccurrenceComplete': 'Complete (TO)'
                  } as const
                  const typeName = typeNameMap[incentive.type as keyof typeof typeNameMap]
                  return editableIncentiveId !== incentive.id ?
                    <Tag
                      key={incentive.id}
                      colorScheme='cyan'
                      onClick={e => { e.stopPropagation(); setEditableIncentiveId(incentive.id) }}
                    >
                      <TagLabel>{`${typeName}: ${incentive.quantity}`}</TagLabel>
                    </Tag> :
                    <Editable
                      defaultValue={`${typeName}: ${incentive.quantity}`}
                      onSubmit={async s => {
                        const sCleaned = s.split(':')[1].trim()
                        if(sCleaned === '') return
                        const quantity = +sCleaned
                        if (Number.isNaN(quantity)) return
                        if (quantity === incentive.quantity) return
                        if (quantity === 0) {
                          await fetch(`${baseUrl}/incentives/${incentive.id}`, {
                            method: 'DELETE'
                          })
                          setEditableIncentiveId(null)
                          updateOccurrences()
                        } else {
                          await fetch(`${baseUrl}/incentives/${incentive.id}/replace`, {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({ quantity })
                          })
                          setEditableIncentiveId(null)
                          updateOccurrences()
                        }
                      }}
                      onClick={e => e.stopPropagation()}
                    >
                      <EditablePreview />
                      <EditableInput />
                    </Editable>
                })}
              </HStack> : null
            }
            <CardButtons
              modelType='taskOccurrence'
              model={occurrence}
              updateStack={updateOccurrences}
            />
          </Stack>
        </Box>
      </Center>
    )
  }