import { useEffect, useState } from 'react'

import {
    Button,
    Input,
    Select,
    Stack
} from '@chakra-ui/react'

import { baseUrl } from '../env'
import dayjs from '../dayjs'
import withCache from '../util/withCache'

import { BareTask, incentiveTypes, IncentiveType, TaskOccurrence } from '../types/models'
type GenericEntity = { id: number, name: string }
type Incentivizables = {
    tasks: BareTask[],
    taskOccurrences: TaskOccurrence[],
    goals: GenericEntity[],
    tactics: GenericEntity[],
    leisureActivities: GenericEntity[]
}

const incentiveTypeToEntitiesMap = {
    TaskStart: ['tasks'],
    TaskContinue: ['tasks'],
    TaskComplete: ['tasks'],
    TaskOccurrenceStart: ['taskOccurrences'],
    TaskOccurrenceContinue: ['taskOccurrences'],
    TaskOccurrenceComplete: ['taskOccurrences'],
    Tactic: ['tactics'],
    TaskTactic: ['tasks', 'tactics'],
    TacticUse: ['tactics', 'taskOccurrences'],
    Goal: ['goals'],
    LeisureActivityStart: ['leisureActivities'],
    LeisureActivityContinue: ['leisureActivities']
} as const
const entityToIdName = {
    tasks: 'taskId',
    taskOccurrences: 'taskOccurrenceId',
    goals: 'goalId',
    tactics: 'tacticId',
    leisureActivities: 'leisureActivityId'
} as const

export default function Link() {
    const [incentiveType, setIncentiveType] = useState<IncentiveType>('TaskComplete')
    const [incentivizables, setIncentivizables] = useState<Incentivizables>({ tasks: [], taskOccurrences: [], goals: [], tactics: [], leisureActivities: [] })
    const [amount, setAmount] = useState('0')
    const [comment, setComment] = useState('')

    const [isDisabled, setIsDisabled] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [didSucceed, setDidSucceed] = useState(false)
    const [didError, setDidError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        withCache<Incentivizables>(
            // Same actual entities for now, so reusing the endpoint
            () => fetch(`${baseUrl}/linkables`).then(r => r.json()),
            'linkables',
            setIncentivizables,
        )
    }, [])
    
    const [entity1Type, entity2Type] = incentiveTypeToEntitiesMap[incentiveType]

    const handleCreatePress = async () => {
        setIsLoading(true)

        const data = {
            type: incentiveType,
            quantity: +amount,
            description: comment,
            [entityToIdName[entity1Type]]: (document.getElementById('entity1') as HTMLSelectElement)?.value,
        }
        if (entity2Type) {
            data[entityToIdName[entity2Type]] = (document.getElementById('entity2') as HTMLSelectElement)?.value
        }
        const response = await fetch(`${baseUrl}/incentives`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        setIsLoading(false)
        if (response.ok) {
            setDidSucceed(true)
        } else {
            setDidError(true)
            console.log(response)
            try {
                const responseText = await response.text()
                console.error(responseText)
                const body = JSON.parse(responseText)
                const message = body && body.message
                if (message) {
                    setErrorMessage(message)
                }
            } catch (e) {
                console.error(e)
            }
        }
        setTimeout(() => {
            setDidError(false)
            setDidSucceed(false)
        }, 5000)
    }

    return (
        <Stack>
        <Select onChange={e => setIncentiveType(e.target.value as IncentiveType)} value={incentiveType}>
            {incentiveTypes.map(type => (
                <option key={type} value={type}>{type}</option>
            ))}
        </Select>
        <Select id='entity1'>
            {incentivizables[entity1Type].map((entity: BareTask|TaskOccurrence|GenericEntity) => {
                const entityName = 'name' in entity ? entity.name :
                    `${entity.task.name} (${entity.date ? dayjs(entity.date).format('dddd, M/DD') : 'undated'})`
                return (<option key={entity.id} value={entity.id}>{entityName}</option>)
            })}
        </Select>
        { entity2Type === undefined ? null :
        <Select id='entity2'>
            {incentivizables[entity2Type].map((entity: BareTask|TaskOccurrence|GenericEntity) => {
                const entityName = 'name' in entity ? entity.name :
                    `${entity.task.name} (${entity.date ? dayjs(entity.date).format('dddd, M/DD') : 'undated'})`
                return (<option key={entity.id} value={entity.id}>{entityName}</option>)
            })}
        </Select>
        }
        <Input type='number' id='amount' placeholder='Amount'
            onChange={e => {
                setAmount(e.target.value)
                setIsDisabled(isNaN(parseInt(e.target.value)))
            }}
            value={amount}
        />
        <Input id='comment' placeholder='Comment'
            onChange={e => setComment(e.target.value)}
            value={comment}
        />
        <Button
            isDisabled={isDisabled}
            isLoading={isLoading}
            colorScheme={didSucceed ? 'green' : didError ? 'red' : 'blue'}
            onClick={handleCreatePress}
        >
            {didSucceed ? 'Created incentive!' : didError ? (errorMessage || 'Failed to create incentive') : 'Create incentive' }
        </Button>
        </Stack>
      )
}