import { StateUser } from  '../types/utility'

export default async function withCache<T>(
    fetchFn: () => Promise<T>,
    cacheKey: string,
    stateSetter: StateUser<T>,
    freshnessSetter?: StateUser<boolean>
): Promise<T> {
    const getFreshData = async () => {
        const data = await fetchFn()
        localStorage.setItem(cacheKey, JSON.stringify(data))
        stateSetter(data)
        if (freshnessSetter) freshnessSetter(true)
        return data
    }
    const cache = localStorage.getItem(cacheKey)
    if (cache) {
        stateSetter(JSON.parse(cache))
        if (freshnessSetter) freshnessSetter(false)
        getFreshData()
        return JSON.parse(cache)
    }
    return getFreshData()
}