import { ChangeEvent, useState } from 'react'

import {
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Input,
    Select,
    Switch,
    VStack,
} from '@chakra-ui/react'

import { baseUrl } from '../env'
import dayjs from '../dayjs'
import getIncentivesFromUida from '../util/getIncentivesFromUida'
import { SpecialTags } from '../util/enums'

const tagToDefaultIncentives = {
    [SpecialTags.KRAKEN]: [['TaskStart', 2], ['TaskContinue', 1], ['TaskComplete', 5]],
    [SpecialTags.IN_TROUBLE]: [['TaskStart', 2], ['TaskContinue', 1], ['TaskComplete', 5]],
} as Record<string, [string, number][]>

const selectedDaysNone = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    .reduce((acc, day) => ({ ...acc, [day]: false }), {})
const incentivesEmpty = [['TaskStart', 0], ['TaskContinue', 0], ['TaskComplete', 0]] as [string, number][]

function TaskInput({ updateOccurrences }: { updateOccurrences: () => void }) {
    const [taskName, setTaskName] = useState('')
    const [taskDescription, setTaskDescription] = useState('')
    const [tags, setTags] = useState<string[]>([])
    const [incentives, setIncentives] = useState<[string, number][]>(incentivesEmpty)
    const [workingUida, setWorkingUida] = useState('0000')
    const [uida, setUida] = useState('0000')
    const [date, setDate] = useState('')
    const [isRecurring, setIsRecurring] = useState(false)
    const [useActiveTime, setUseActiveTime] = useState(false)
    const [activeTime, setActiveTime] = useState('')
    const [selectedDays, setSelectedDays] = useState<{ [day: string]: boolean }>(selectedDaysNone)
    const [period, setPeriod] = useState(NaN)

    const handleTaskNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        const lowerTags = tags.map(tag => tag.toLowerCase())
        if (taskName.startsWith('K:') && !lowerTags.includes(SpecialTags.KRAKEN)) {
            updateTags([...tags, SpecialTags.KRAKEN])
        } else if (taskName.startsWith('T:') && !lowerTags.includes(SpecialTags.IN_TROUBLE)) {
            updateTags([...tags, 'inTrouble'])
        }
        setTaskName(event.currentTarget.value)
    }
    const handleTaskDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => setTaskDescription(event.currentTarget.value)
    const handleTagChange = (event: ChangeEvent<HTMLInputElement>) => updateTags(event.currentTarget.value.split(',').map(s => s.trim()))
    const handleIncentiveValueChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
        const value = Number(event.target.value) || 0
        setIncentives(incentives.map(([t, p], i) => i === index ? [t, value] : [t, p]))
    }
    const handleUidaChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newUida = event.currentTarget.value
        setWorkingUida(newUida)
        if (!/^[01234]{4}$/.test(newUida)) {
            return
        }
        setUida(newUida)
        if (!newUida.includes('0')) {
            setIncentives(getIncentivesFromUida(newUida))
        }
    }
    const handlePeriodChange = (event: ChangeEvent<HTMLInputElement>) => setPeriod(Number(event.currentTarget.value))
    const handleDateChange = (event: ChangeEvent<HTMLInputElement>) => {
        let time
        try {
            time = dayjs(event.currentTarget.value).format('YYYY-MM-DD')
        } catch (error) {
            console.error(error)
        }
        setDate(time || '')
    }
    const handleActiveTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
        let time
        try {
            time = dayjs(event.currentTarget.value).toISOString()
        } catch (error) {
            console.error(error)
        }
        setActiveTime(time || '')
    }

    const updateTags = (tags: string[]) => {
        setTags(tags)
        const lowerTags = tags.map(tag => tag.toLowerCase())
        const incentivizedTag = lowerTags.find(tag => tag in tagToDefaultIncentives)
        if (incentivizedTag) {
            const incentives = tagToDefaultIncentives[incentivizedTag as keyof typeof tagToDefaultIncentives]
            setIncentives(incentives)
        }
    }

    const [isLoading, setIsLoading] = useState(false)
    const [didError, setDidError] = useState(false)
    const [didSucceed, setDidSucceed] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const handleCreatePress = async () => {
        const [urgency, importance, difficulty, avoidance] =
            uida.split('').map(Number)

        const schedule = Object.entries(selectedDays)
        .filter(([_, selected]) => selected)
        .map(([day, _]) => day)
        setIsLoading(true)
        const body = {
            name: taskName,
            isRecurring,
            schedule,
            tags,
            incentives: incentives.filter(([_, points]) => points > 0),
            urgency,
            importance,
            difficulty,
            avoidance
        } as Record<string, any>
        if (taskDescription.length) {
            body.description = taskDescription
        }
        if (period) {
            body.period = period
        }
        if (activeTime) {
            body.activeTime = activeTime
        }
        if (date) {
            body.date = date
        }
        const response = await fetch(`${baseUrl}/tasks`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
        setIsLoading(false)
        if (response.ok) {
            setDidSucceed(true)
            setTaskName('')
            setTaskDescription('')
            setTags([])
            setIncentives(incentivesEmpty)
            setUida('0000')
            setIsRecurring(false)
            setSelectedDays(selectedDaysNone)
            setPeriod(NaN)
            updateOccurrences()
        } else {
            setDidError(true)
            const body = await response.json()
            const message = body && body.message
            if (message) {
                setErrorMessage(message)
            }
        }
        setTimeout(() => {
            setDidError(false)
            setDidSucceed(false)
        }, 5000)
    }

    return (
        <Box maxWidth='100%' overflowX='auto'>
        <VStack align='stretch'>
            <Card>
                <CardHeader style={{ paddingBottom: '0px' }}>
                    <Heading size="md">Create task</Heading>
                </CardHeader>
                <CardBody>
                    Task name: <Input value={taskName} onChange={handleTaskNameChange} size='md' />
                    Task description: <Input value={taskDescription} onChange={handleTaskDescriptionChange} size='md' />
                    Tags: <Input value={tags.join(', ')} onChange={handleTagChange} size='md' />
                    Incentives: {incentives.map(([type, val], index) => (
                        <Box key={index} display='flex'>
                            <Select defaultValue={type} size='md' maxWidth='230px' onChange={e => { 
                                    setIncentives(incentives.map(([t, p], i) => i === index ? [e.target.value, p] : [t, p]))
                                }}
                            >
                                { /* Yes, it's silly that it's just these three when they're always displayed -
                                     leaving the select code in here for possible future uses,
                                     such as not displaying all three by default */ }
                                { ['TaskStart', 'TaskContinue', 'TaskComplete'].map(type => (
                                    <option key={type} value={type}>{type.slice(4)}</option>
                                ))}
                            </Select>
                            <Input value={val === 0 ? '' : val} size='md' maxWidth='60px' marginLeft='10px' onChange={e => handleIncentiveValueChange(e, index)} />
                        </Box>
                    ))}
                    UIDA: <Input value={workingUida} onChange={handleUidaChange} size='md' />
                    {useActiveTime ?
                        <div>Active time: <Input placeholder='Select Date and Time' size='md' type='datetime-local' onChange={handleActiveTimeChange} /></div> :
                        <div>Date: <Input placeholder='Select Date' size='md' type='date' onChange={handleDateChange} /></div>
                    }
                <div /><br />
                <FormControl display='flex' alignItems='center'>
                    <VStack>
                    <HStack>
                        <FormLabel htmlFor='task-date' mb='0'>
                            Use active time
                        </FormLabel>
                        <Switch
                            id='active-time'
                            // Makes it more or less line up with switch below
                            marginLeft={1}
                            onChange={e => {
                                setUseActiveTime(e.target.checked)
                                setDate('')
                                setActiveTime('')
                            }}
                        />
                    </HStack>
                    <HStack>
                        <FormLabel htmlFor='is-recurring' mb='0'>
                            Is recurring task
                        </FormLabel>
                        <Switch
                            id='recurring-task'
                            onChange={e => setIsRecurring(e.target.checked)}
                        />
                    </HStack>
                    </VStack>
                </FormControl>
                {isRecurring &&
                    <div>
                        <HStack marginTop='10px' marginBottom='10px'>
                            {Object.keys(selectedDays).map(day => (
                                <Button
                                    key={day}
                                    colorScheme={selectedDays[day] ? 'green' : 'gray'}
                                    onClick={() => setSelectedDays({ ...selectedDays, [day]: !selectedDays[day] })}
                                >
                                    {day}
                                </Button>
                            ))}
                        </HStack>
                        <div>OR</div>
                        <div>Recurs every{' '}
                            <Input
                                value={period || ''}
                                onChange={handlePeriodChange}
                                size='sm'
                                width='50px'
                            />
                            {' '}days
                        </div>
                    </div>
                }
                </CardBody>
            </Card>
            <Button
                isDisabled={!taskName.length}
                isLoading={isLoading}
                colorScheme={didSucceed ? 'green' : didError ? 'red' : 'blue'}
                onClick={handleCreatePress}
            >
                {didSucceed ? 'Created task!' : didError ? (errorMessage || 'Failed to create task') : 'Create task' }
            </Button>
        </VStack>
        </Box>
    )
}

export default TaskInput