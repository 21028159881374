export default function getIncentivesFromUida(uida: string) {
    if (uida.includes('0')) return [] // Only try to do this when all four are set
    const [urgency, importance, difficulty, avoidance] = uida.split('').map(Number)
    const startQuantity = (urgency === 1 ? 1 : 2 ** urgency) + (avoidance === 1 ? 1 : 2 ** avoidance)
    const continueQuantity = Math.round((urgency + importance + difficulty + avoidance) / 4)
    const completeQuantity = (urgency + importance + difficulty + avoidance) < 10 ?
        urgency * importance * difficulty * avoidance :
        Math.round([urgency, importance, difficulty, avoidance].map(n => {
            // Unprincipled modification to make the incentives not balloon quite so much at higher values
            if (n === 4) return 3.5
            if (n === 3) return 2.75
            return n
        }).reduce((a, b) => a * b, 1))

    return [
        ['TaskStart', startQuantity],
        ['TaskContinue', continueQuantity],
        ['TaskComplete', completeQuantity]
    ] as [string, number][]
}