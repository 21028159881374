import { useState } from 'react'

import {
    Box,
    Center,
    CloseButton,
    Editable,
    EditableInput,
    EditablePreview,
    EditableTextarea,
    Flex,
    Heading,
    HStack,
    Stack,
    Tag,
    TagLabel,
    Text,
} from '@chakra-ui/react'
import { MinusIcon } from '@chakra-ui/icons'

import CardButtons from './CardButtons'

import { baseUrl } from '../env'
import { confirmableCardColors } from '../styles'
import { LeisureActivity } from '../types/models'

function LeisureActivityCard({ cardData, fetchCards, refreshCards }: { cardData: LeisureActivity, fetchCards: () => void, refreshCards: () => void}) {
    const [deletionIsConfirmable, setDeletionIsConfirmable] = useState(false)
    const [nameEditMode, setNameEditMode] = useState(false)
    const [name, setName] = useState(cardData.name)
    const [descriptionEditMode, setDescriptionEditMode] = useState(false)
    const [description, setDescription] = useState(cardData.description)
    const [tagEditMode, setTagEditMode] = useState(false)

    const { id } = cardData
    const lsName = 'collapsedLeisureActivities'

    const style = confirmableCardColors['pending']

    const tagRow = <HStack width='full' onClick={e => { e.stopPropagation(); setTagEditMode(true) }}>
        {cardData.tags.map(tag => (
        <Tag
        size='sm'
        key={tag}
        >
        <TagLabel>{tag}</TagLabel>
        </Tag>
    ))}
    </HStack>

    return <Center py={3}>
        <Box
        maxW={'445px'}
        w={'full'}
        bg={style.bg}
        boxShadow={'2xl'}
        rounded={'md'}
        p={6}
        overflow={'hidden'}
        >
        <Flex justifyContent='space-between' w='full' onClick={e => e.stopPropagation()}>
            <MinusIcon onClick={() => {
                // Toggle inclusion in collapsedCards
                const collapsedCards = JSON.parse(localStorage.getItem(lsName) || '[]')
                localStorage.setItem(lsName, JSON.stringify(
                    collapsedCards.includes(id) ?
                    collapsedCards.filter((c: string) => c !== id) :
                    [...collapsedCards, id]
                ))
                refreshCards()
            }}/>
            <CloseButton
                color={deletionIsConfirmable ? 'red' : 'black'}
                onClick={async () => {
                if (deletionIsConfirmable) {
                    await fetch(`${baseUrl}/leisureActivities/${id}`, {
                        method: 'DELETE'
                    })
                    fetchCards()
                } else {
                    setDeletionIsConfirmable(true)
                    setTimeout(() => setDeletionIsConfirmable(false), 2000)
                }
            }}/>
        </Flex>
        <Stack>
            { !nameEditMode ?
                <Heading
                color={style.dayColor}
                fontSize={'2xl'}
                fontFamily={'body'}
                onClick={() => setNameEditMode(true)}
                >
                    {name}
                </Heading> :
                <Editable
                    defaultValue={name}
                    onSubmit={async s => {
                        const sTrimmed = s.trim()
                        if (!sTrimmed) return
                        setNameEditMode(false)
                        if (sTrimmed !== name) {
                            setName(s)
                            await fetch(`${baseUrl}/leisureActivities/${cardData.id}`, {
                                method: 'PATCH',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify({ name: s })
                            })
                            fetchCards()
                        }
                    }}
                    onClick={e => e.stopPropagation()}
                >
                    <EditablePreview />
                    <EditableInput />
                </Editable>
            }
            { !descriptionEditMode ?
                <Text
                color={style.descriptionColor}
                onClick={() => setDescriptionEditMode(true)}
                >
                    {(description || '').padEnd(40, String.fromCharCode(8194)).slice(0, 100)}
                </Text> :
                <Editable
                    defaultValue={description || ''}
                    onSubmit={async s => {
                        const sTrimmed = s.trim()
                        setDescriptionEditMode(false)
                        if (sTrimmed !== description) {
                            setDescription(sTrimmed)
                            await fetch(`${baseUrl}/leisureActivities/${cardData.id}`, {
                                method: 'PATCH',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify({ description: sTrimmed || null })
                            })
                            fetchCards()
                        }
                    }}
                    onClick={e => e.stopPropagation()}
                >
                    <EditablePreview w='full'/>
                    <EditableTextarea w='full'/>
                </Editable>
            }
            { tagEditMode ? null : !cardData.tags.length ?
              <Text
                color={style.descriptionColor}
                onClick={e => { e.stopPropagation(); setTagEditMode(true) }}
              >
                No tags
              </Text> : tagRow
            }
            {
              !tagEditMode ? null :
              <Editable
                defaultValue={cardData.tags.join(', ')}
                placeholder='No tags'
                onSubmit={async s => {
                  const tags = s.split(',').map(t => t.trim()).filter(t => t)
                  cardData.tags = tags
                  await fetch(`${baseUrl}/leisureActivities/${cardData.id}`, {
                    method: 'PATCH',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ tags })
                  })
                  fetchCards()
                  setTagEditMode(false)
                }}
                onClick={e => e.stopPropagation()}
              >
                <EditablePreview />
                <EditableInput />
              </Editable>
            }
            {
                cardData.incentives.length ?
                <HStack>
                {cardData.incentives
                .map(incentive => {
                    return (
                    <Tag key={incentive.id} colorScheme='cyan'>
                        <TagLabel>{`${incentive.type.slice(15)}: ${incentive.quantity}`}</TagLabel>
                    </Tag>
                    )
                })}
                </HStack> : null
            }
            <CardButtons modelType='leisureActivity' model={cardData} updateStack={fetchCards} />
        </Stack>
        </Box>
    </Center>
}

export default LeisureActivityCard