import { useState } from 'react'

import _ from 'lodash'
import {
    Button,
    Flex,
    Select
} from '@chakra-ui/react'

import { baseUrl } from '../env'

import { LeisureActivity, TaskOccurrence } from '../types/models'

const makeHandler = (actionName: string) => {
    const fn = async function(modelType: 'taskOccurrence' | 'leisureActivity', model: TaskOccurrence | LeisureActivity, updateStack: () => void) {
        const path = modelType === 'taskOccurrence' ?
            `${baseUrl}/tasks/${(model as TaskOccurrence).task.id}/occurrences/${model.id}/${actionName}` :
            `${baseUrl}/leisureActivities/${model.id}/${actionName}`
        await fetch(path, { method: 'POST'})
        await updateStack()
    }

    return _.debounce(fn, 2000, { leading: true, trailing: false })
}

const handleStart = makeHandler('start')
const handleContinue = _.debounce(async function(
        modelType: 'taskOccurrence' | 'leisureActivity',
        model: TaskOccurrence | LeisureActivity,
        updateStack: () => void,
        repeats: number
    ) {
        const path = modelType === 'taskOccurrence' ?
            `${baseUrl}/tasks/${(model as TaskOccurrence).task.id}/occurrences/${model.id}/continue` :
            `${baseUrl}/leisureActivities/${model.id}/continue`
        for (let i = 0; i < repeats; i++) {
            await fetch(path, { method: 'POST'})
        }
        await updateStack()
    }, 2000, { leading: true, trailing: false })
const handleComplete = makeHandler('complete')

type ButtonState = 'normal' | 'confirmable' | 'confirmed' | 'error' | 'disabled'
const initialButtonStates = {
    start: 'normal' as ButtonState,
    continue: 'normal' as ButtonState,
    complete: 'normal' as ButtonState
}
type ButtonName = keyof typeof initialButtonStates

export default function CardButtons({
  modelType,
  model,
  updateStack
}:
  {
    modelType: 'taskOccurrence' | 'leisureActivity',
    model: TaskOccurrence | LeisureActivity,
    updateStack: () => void
  }) {
    const [buttonStates, setButtonStates] = useState(initialButtonStates)
    const [continueCount, setContinueCount] = useState(1)

    const handleClick = (buttonName: ButtonName, handler: () => Promise<any>) => {
        const buttonState = buttonStates[buttonName]
        const resetButtonStates = () => {
            setButtonStates(initialButtonStates)
        }
        if (buttonState === 'confirmable') {
          setButtonStates({ ...initialButtonStates, [buttonName]: 'confirmed' })
          const time = Date.now()
          handler()
          .then(() => {
            const timeSinceConfirmation = Date.now() - time
            if (timeSinceConfirmation < 500) {
                setTimeout(resetButtonStates, 500 - timeSinceConfirmation)
            } else {
                resetButtonStates()
            }
          })
          .catch(err => {
            console.error(err)
            setButtonStates({ ...initialButtonStates, [buttonName]: 'error' })
          })
        } else {
          setButtonStates({ ...initialButtonStates, [buttonName]: 'confirmable' })
          setTimeout(() => {
            setButtonStates((prevButtonStates) => {
                if (prevButtonStates[buttonName] === 'confirmable') {
                    return initialButtonStates
                }
                return prevButtonStates
            })
          }, 2000)
        }  
      }

    const confirmableColor = 'yellow.500'
    const confirmedColor = 'green.500'
    const errorColor = 'red.500'
    const defaultButtonColors = {
        start: 'blue.300',
        continue: 'blue.200',
        complete: '#2222ff'
    }
    const getButtonColor = (buttonName: ButtonName) => {
        const buttonState = buttonStates[buttonName]
        if (buttonState === 'confirmable') {
            return confirmableColor
        } else if (buttonState === 'confirmed') {
            return confirmedColor
        } else if (buttonState === 'error') {
            return errorColor
        } else {
            return defaultButtonColors[buttonName]
        }
    }

    const startButton = <Button
        onClick={(event) => {
            event.stopPropagation()
            handleClick('start', () => handleStart(modelType, model, updateStack))
        }}
        _active={{}}
        _hover={{}}
        backgroundColor={getButtonColor('start')}
        style={{
            color: 'black',
            zIndex: 1000,
        }}
    >
        Start
    </Button>

    const continueButton = <Button
        onClick={(event) => {
            event.stopPropagation()
            handleClick('continue', () => handleContinue(modelType, model, updateStack, continueCount))
        }}
        _active={{}}
        _hover={{}}
        backgroundColor={getButtonColor('continue')}
        style={{
          marginLeft: '10px',
          color: 'black',
          zIndex: 1000,
        }}
    >
        Continue
    </Button>
    const continueCountSelect = <Select
        marginLeft='5px'
        width={20}
        value={continueCount}
        onChange={(event) => setContinueCount(+event.target.value)}
    >
        {_.range(1, 21).map(count => <option key={count} value={count}>{count}</option>)}
    </Select>

    const completeButton = <Button
        onClick={(event) => {
            event.stopPropagation()
            handleClick('complete', () => handleComplete(modelType, model, updateStack))
        }}
        _active={{}}
        _hover={{}}
        backgroundColor={getButtonColor('complete')}
        style={{
          marginLeft: '10px',
          color: 'white',
          zIndex: 1000,
        }}
    >
      Complete
    </Button>

    return <Flex justifyContent='space-between' flexDirection='row'>
        {startButton}
        {continueButton}
        {continueCountSelect}
        {completeButton}
    </Flex>
}
