import { useEffect, useState } from 'react'
import {
    Badge,
    Box,
    Button,
    CircularProgress,
    HStack,
    Input,
    Text,
    VStack
} from '@chakra-ui/react'

import { baseUrl } from '../env'
import dayjs from '../dayjs'
import withCache from '../util/withCache'

interface Incentive {
    type: string
    task?: { name: string }
    tactic?: { name: string }
    goal?: { name: string }
    leisureActivity?: { name: string }
}

interface PointChange {
    id: string
    quantity: number
    reason?: string
    createdAt: string
    incentive?: Incentive,
    instances: number
}

export default function PointChanges() {
    const [pointChanges, setPointChanges] = useState<PointChange[]>([])
    const [dataIsFresh, setDataIsFresh] = useState<boolean>(false)

    useEffect(() => {
        withCache<PointChange[]>(
            () => fetch(`${baseUrl}/pointChanges`).then(r => r.json()),
            'pointChanges',
            setPointChanges,
            setDataIsFresh
        )
    }, [])

    const totalPoints = pointChanges.reduce((acc, { quantity }) => acc + quantity, 0)
    const pointsGainedToday = pointChanges
        .filter( ({ quantity, createdAt }) => dayjs(createdAt).isSame(dayjs(), 'day') && quantity > 0)
        .reduce((acc, { quantity }) => acc + quantity, 0)
    const pointsLostToday = pointChanges
        .filter( ({ quantity, createdAt }) => dayjs(createdAt).isSame(dayjs(), 'day') && quantity < 0)
        .reduce((acc, { quantity }) => acc + quantity, 0)

    return (
        <VStack spacing={4} align="stretch">
            <CircularProgress
                value={dataIsFresh ? 100 : 50}
                color={dataIsFresh ? 'green' : 'red' }
                size='30px'
            />
            <Text>Total points: {totalPoints}<br/>
                  Points gained today: {pointsGainedToday}<br/>
                  Points spent today: {pointsLostToday * -1}
            </Text>
            <HStack spacing={4}>
                <Input maxWidth='200px' type="number" placeholder="Dollars to redeem" />
                <Button onClick={() => {
                    const input = document.querySelector('input') as HTMLInputElement
                    const quantity = +input.value
                    if (!quantity) return
                    if (totalPoints < quantity * 2) return
                    fetch(`${baseUrl}/pointChanges/redeem`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ quantity })
                    })
                    .then(() => {
                        input.value = ''
                        setDataIsFresh(false)
                        withCache<PointChange[]>(
                            () => fetch(`${baseUrl}/pointChanges`).then(r => r.json()),
                            'pointChanges',
                            setPointChanges,
                            setDataIsFresh
                        )
                    })
                }}>Redeem</Button>
            </HStack>
            {pointChanges
                .map(({ id, quantity, reason, incentive, instances, createdAt }) => {
                    const color = quantity > 0 ? 'green' : 'red'
                    if (!incentive) {
                        return (
                            <Box key={id} p={1} borderWidth="1px" borderRadius="lg">
                                <HStack justify="left" spacing={4}>
                                    <Text fontWeight="bold">{`${quantity > 0 ? '+' : ''}${quantity}`}</Text>
                                    { reason && reason.startsWith('Redeemed') && <Badge colorScheme='yellow'>Redemption</Badge> }
                                    <Text>{dayjs(createdAt).format('dddd M/DD, hh:mm A')}</Text>
                                </HStack>
                                {reason && <Text>{reason}</Text>}
                            </Box>
                        )
                    }
                    return (
                    <Box key={id} p={1} borderWidth="1px" borderRadius="lg">
                        <HStack justify="left" spacing={4}>
                            <Text fontWeight="bold">{`${quantity > 0 ? '+' : ''}${quantity}`}</Text>
                            { incentive && <Badge colorScheme={color}>{`${incentive.type}${instances > 1 ? ` (x${instances})` : ''}`}</Badge> }
                            <Text>{dayjs(createdAt).format('dddd M/DD, hh:mm A')}</Text>
                        </HStack>
                        {reason && <Text>Reason: {reason}</Text>}
                        {incentive && (
                            <Box mt={2}>
                                {incentive.task && <Text>Task: {incentive.task.name}</Text>}
                                {incentive.tactic && <Text>Tactic: {incentive.tactic.name}</Text>}
                                {incentive.goal && <Text>Goal: {incentive.goal.name}</Text>}
                                {incentive.leisureActivity && <Text>Leisure Activity: {incentive.leisureActivity.name}</Text>}
                            </Box>
                        )}
                    </Box>
                )})}
        </VStack>
    )
}